import React, {useContext} from "react";
import "./SkillProgress.css";
import SkillProgressCard from "../../components/skillProgressCard/SkillProgressCard";
import {techStack} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

export default function SkillProgress() {
  const {isDark} = useContext(StyleContext);
  if (techStack.display) {
    return (
      <div id="skill-progress">
        <Fade bottom duration={1000} distance="20px">
          <div className="skill-progress-container" id="skillProgress">
            <div>
              <h1 className="skill-progress-heading">Skills</h1>
              <p
                className={
                  isDark
                    ? "dark-mode subTitle skills-text-subtitle"
                    : "subTitle skills-text-subtitle"
                }
              >
                {techStack.subTitle}
              </p>
              <div className="skill-progress-cards-div">
                {techStack.experience.map((card, i) => {
                  return (
                    <SkillProgressCard
                      key={i}
                      isDark={isDark}
                      cardInfo={{
                        stack: card.Stack,
                        descBullets: card.DescBullets
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
  return null;
}
