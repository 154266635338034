import React from "react";
import "./Footer.css";
import {Fade} from "react-reveal";
// import emoji from "react-easy-emoji";
// import StyleContext from "../../contexts/StyleContext";

export default function Footer() {
  // const {isDark} = useContext(StyleContext);
  return (
    <Fade bottom duration={1000} distance="5px">
      <div className="footer-div">
        {/* <p className={isDark ? "dark-mode footer-text" : "footer-text"}> */}
        {/* {emoji("Made with ❤️ by Saad Pasta")} */}
        {/* </p> */}
        {/* <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          Theme by <a href="https://github.com/petrogko">developerFolio</a>
        </p> */}
        {/* <div>Icons made by <a href="https://www.flaticon.com/authors/itim2101" title="itim2101">itim2101</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> */}
      </div>
    </Fade>
  );
}
