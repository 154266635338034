import React, {useState, createRef} from "react";
import "./ProgressCard.css";
import ColorThief from "colorthief";

export default function StackProgressCard({cardInfo, isDark}) {
  const [colorArrays, setColorArrays] = useState([]);
  const imgRef = createRef();

  function getColorArrays() {
    const colorThief = new ColorThief();
    setColorArrays(colorThief.getColor(imgRef.current));
  }

  function rgb(values) {
    return typeof values === "undefined"
      ? null
      : "rgb(" + values.join(", ") + ")";
  }

  const GetDescBullets = ({descBullets, isDark}) => {
    return descBullets
      ? descBullets.map((item, i) => (
          <li
            key={i}
            className={isDark ? "subTitle dark-mode-text" : "subTitle"}
          >
            {item}
          </li>
        ))
      : null;
  };

  return (
    <div
      className={isDark ? "skill-progress-card-dark" : "skill-progress-card"}
    >
      <div
        style={{background: rgb(colorArrays)}}
        className="skill-progress-banner"
      >
        <div className="skill-progress-blurred_div"></div>
        <div className="skill-progress-div-company">
          <h5
            className="skill-progress-text-company"
            onLoad={() => getColorArrays()}
          >
            {cardInfo.stack}
          </h5>
        </div>
      </div>
      <div className="skill-progress-text-details">
        <ul>
          <GetDescBullets descBullets={cardInfo.descBullets} isDark={isDark} />
        </ul>
      </div>
    </div>
  );
}
